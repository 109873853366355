import React from 'react';
import { graphql } from 'gatsby';

import Section from '@kastle/ui/src/atoms/Section/Section';
import { SectionType } from '@kastle/ui/src/atoms/Section/Section.types';
import { HeadingTypes } from '@kastle/ui/src/atoms/Heading/Heading.types';
import ArticleContentList from '@kastle/components/src/components/Article/ArticleContentList';
import Pager from '@kastle/components/src/components/Pager/pager';
import ArticleListLayout from '../components/Layout/ArticleList';

const BlogPage = (props) => {
  const {
    pageContext,
    data: { articles },
  } = props;
  const list = articles.edges.map(({ node }) => node);
  return (
    <ArticleListLayout {...props} pageContext={pageContext}>
      <Section type={SectionType.Secondary}>
        <ArticleContentList articles={list} as={HeadingTypes.h2} />
      </Section>
      <Section type={SectionType.Secondary}>
        <Pager
          previousPagePath={pageContext.previousPagePath}
          nextPagePath={pageContext.nextPagePath}
        />
      </Section>
    </ArticleListLayout>
  );
};

export const pageQuery = graphql`
  query allArticlesBlog($skip: Int!, $limit: Int!, $content: String!) {
    articles: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
      filter: {
        frontmatter: { template: { eq: "post" } }
        fileAbsolutePath: { regex: $content }
      }
    ) {
      edges {
        node {
          fields {
            slug
            path
            categories {
              name
              link
            }
            tags {
              name
              link
            }
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 500, layout: CONSTRAINED)
              }
            }
            date
            template
          }
        }
      }
    }
  }
`;
export default BlogPage;
